import React from 'react';
import { pick } from 'lodash';
import { SiTarget } from 'react-icons/si';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import {
    Box,
    SimpleGrid,
    VStack,
    Text,
    IconButton,
    Button,
    HStack,
    GridItem,
    Progress,
    Menu,
    MenuButton,
    chakra,
    MenuList,
    MenuItem,
    MenuDivider,
} from '@chakra-ui/react';
import {
    getDaysInMonth,
    subMonths,
    addMonths,
    format,
    eachDayOfInterval,
    getDay,
    isAfter,
    isWithinInterval,
    startOfMonth,
    endOfMonth,
} from 'date-fns';
// import { differenceInBusinessDays } from 'date-fns';
import { Card, CardContent } from '@ucc/react/ui';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { Form } from '~/components/Form/Form';
import { selects } from '~/meta/data/selects.gql';
import { months, weekdays } from '~/pages/staffing/meta/data/absences.schema';
import { useAbsences } from '../hooks/useAbsences';
import { PageHeader } from '~/components/Layout/PageHeader';
import { usePermission } from '~/hooks';
import { join } from '~/utils';

export function generateDays(date: Date) {
    const month = eachDayOfInterval({
        start: new Date(date.getFullYear(), date?.getMonth(), 1),
        end: new Date(date.getFullYear(), date?.getMonth(), getDaysInMonth(date)),
    });

    return month?.map((date) => ({
        date: format(date, 'yyyy-MM-dd'),
        weekday: weekdays?.[getDay(date)],
        day: format(date, 'dd'),
    }));
}

export function Absences({ title }: { title: string }) {
    const permissions = usePermission('staff.absences');
    const { control, watch } = useForm<{ branch?: any; employeeFunction?: any }>({
        defaultValues: { branch: { value: 1 } },
    });
    const today = new Date();
    const [selectedDate, setSelectedDate] = React.useState(today);
    const selectedMonth = months?.[selectedDate?.getMonth()];
    const days = generateDays(selectedDate);
    const fromDate = startOfMonth(selectedDate);
    const untilDate = endOfMonth(selectedDate);

    const prev = () => setSelectedDate(subMonths(selectedDate, 1));
    const next = () => setSelectedDate(addMonths(selectedDate, 1));

    const { branch, employeeFunction } = watch();
    const branchId = branch?.value;
    const employeeFunctionId = employeeFunction?.value;

    const { loading, schedule, onEdit, onDelete } = useAbsences({
        branchId,
        employeeFunctionId,
        date: selectedDate,
        fromDate,
        untilDate,
    });

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
                <PageHeader.Actions>
                    <Form>
                        <SimpleGrid columns={2} spacing={6} w="450px">
                            <QuerySelect
                                control={control}
                                name="branch"
                                placeholder="Niederlassung"
                                query={selects.branches}
                                mapOptions={(item) => ({
                                    value: item?.value,
                                    label: `${item?.nummer} - ${item?.bezeichnung}`,
                                })}
                            />
                            <QuerySelect
                                control={control}
                                isClearable
                                name="employeeFunction"
                                placeholder="Funktion"
                                query={selects.functions}
                            />
                            {/* <QuerySelect
                        control={control}
                        isClearable
                        name="role"
                        placeholder="Rolle"
                        query={selects.function}
                    /> */}
                        </SimpleGrid>
                    </Form>
                </PageHeader.Actions>
            </PageHeader>
            <Card boxShadow="none" transform="translate(0, -40px)">
                <CardContent>
                    <VStack spacing={0} align="start" position="sticky" top="0">
                        <HStack
                            justify="space-between"
                            w="full"
                            alignItems="center"
                            p={6}
                            flex={1}
                            bg="white"
                            pt="80px"
                        >
                            <VStack align="flex-start" spacing={1}>
                                <Text fontSize={18} fontWeight="bold" color="gray.900">
                                    {selectedMonth}
                                </Text>
                                <Text fontSize={14} color="gray.600" fontWeight="light">
                                    {selectedDate?.getFullYear()}
                                </Text>
                            </VStack>
                            <SimpleGrid columns={months.length + 2} spacing={1}>
                                <IconButton
                                    aria-label="previous"
                                    icon={<FiChevronLeft />}
                                    variant="ghost"
                                    onClick={prev}
                                    px={1}
                                />
                                {months?.map((month, idx) => {
                                    const isActive = selectedMonth === month;
                                    return (
                                        <Button
                                            px={1}
                                            variant="ghost"
                                            key={[month, isActive && 'isActive']
                                                .filter(Boolean)
                                                .join('.')}
                                            {...{ ...(isActive && { boxShadow: 'outline' }) }}
                                            onClick={() =>
                                                setSelectedDate(
                                                    new Date(selectedDate?.getFullYear(), idx)
                                                )
                                            }
                                        >
                                            {month.slice(0, 3)}
                                        </Button>
                                    );
                                })}
                                <IconButton
                                    aria-label="next"
                                    onClick={next}
                                    icon={<FiChevronRight />}
                                    variant="ghost"
                                    px={1}
                                />
                            </SimpleGrid>
                            <IconButton
                                isDisabled={selectedMonth === months?.[today?.getMonth()]}
                                color="gray.200"
                                _hover={{ color: 'blue.500' }}
                                aria-label="next"
                                onClick={() => setSelectedDate(today)}
                                icon={<SiTarget />}
                                variant="ghost"
                                px={1}
                            />
                        </HStack>
                        <HStack
                            w="full"
                            bg="gray.50"
                            color="gray.600"
                            fontWeight="bold"
                            align="flex-start"
                            borderWidth={1}
                            borderColor="gray.200"
                        >
                            <Text px={6} pt={3} pb={7} w={229}>
                                Mitarbeiter
                            </Text>
                            <SimpleGrid columns={getDaysInMonth(selectedDate)} w="full">
                                {days?.map(({ day, weekday }: any) => {
                                    return (
                                        <GridItem
                                            pt={3}
                                            pb={7}
                                            h="full"
                                            colSpan={1}
                                            key={`${weekday}.${day}`}
                                            {...{
                                                ...(['SA', 'SO'].includes(weekday) && {
                                                    bg: 'gray.300',
                                                }),
                                            }}
                                        >
                                            <VStack spacing={0} px={2} py={3}>
                                                <Text>{weekday}</Text>
                                                <Text fontWeight="light">{day}</Text>
                                            </VStack>
                                        </GridItem>
                                    );
                                })}
                            </SimpleGrid>
                        </HStack>
                        {loading && (
                            <Progress size="xs" colorScheme="cyan" isIndeterminate w="full" />
                        )}
                    </VStack>
                    {schedule?.map((employee: any) => (
                        <Absence
                            key={employee?.employeeId}
                            days={days}
                            employee={employee}
                            selectedDate={selectedDate}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            {...permissions}
                        />
                    ))}
                </CardContent>
            </Card>
        </>
    );
}

const Absence = ({
    employee,
    selectedDate,
    days,
    onDelete,
    onEdit: onEditFn,
    canCreate,
    canDelete,
    canEdit,
}: any) => {
    const absences = employee?.absences;
    const periodSummary = joinAbsences(absences);

    const existsInIntervall = React.useCallback(
        (date: any) =>
            periodSummary
                ?.map((item: any) => {
                    return !isAfter(new Date(item.datumFrom), new Date(item.datumUntil)) &&
                        isWithinInterval(new Date(date), {
                            start: new Date(item.datumFrom),
                            end: new Date(item.datumUntil),
                        })
                        ? item
                        : null;
                })
                .find(Boolean),
        [periodSummary]
    );

    employee.displayName === 'Bensch, Kevin' &&
        console.log('Absence!', { days, periodSummary, employee, absences, existsInIntervall });

    return (
        <HStack
            key={employee?.id}
            bg="white"
            color="gray.600"
            fontWeight="bold"
            borderBottomWidth={1}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderColor="gray.200"
            h="72px"
            align="center"
        >
            <Text px={6} w={229} overflow="hidden">
                {employee?.displayName}
            </Text>
            <SimpleGrid
                columns={getDaysInMonth(selectedDate)}
                w="full"
                h="72px"
                data-test-id="employee"
            >
                {days?.map(({ day, weekday, date }: any) => {
                    const inIntervall = existsInIntervall(date);

                    function onEdit() {
                        onEditFn?.({
                            datumFrom: date,
                            datumUntil: date,
                            mitarbeiterId: employee.employeeId,
                            ...(inIntervall && inIntervall),
                        });
                    }

                    return (
                        <GridItem
                            key={`${weekday}.${day}`}
                            data-test-id={join(
                                ['employee', 'absence', inIntervall?.abwesenheitId],
                                '-'
                            )}
                            {...(!inIntervall && canCreate && { onClick: onEdit })}
                            cursor={inIntervall ? 'pointer' : !canCreate ? 'default' : 'copy'}
                            h="full"
                            colSpan={1}
                            py={6}
                            {...(['SA', 'SO'].includes(weekday) && { bg: 'gray.200' })}
                        >
                            {inIntervall && (
                                <Menu>
                                    <MenuButton
                                        data-test-id={`menu-${inIntervall.abwesenheitId}`}
                                        textAlign="start"
                                        w="full"
                                        bg="blackAlpha.500"
                                        h="full"
                                        display="flex"
                                        alignItems="center"
                                        color="white"
                                        pl={2}
                                        {...(inIntervall?.datumFrom === date && {
                                            roundedTopLeft: 'md',
                                            roundedBottomLeft: 'md',
                                        })}
                                        {...(inIntervall?.datumUntil === date && {
                                            roundedTopRight: 'md',
                                            roundedBottomRight: 'md',
                                        })}
                                    >
                                        <chakra.span h="full">
                                            {inIntervall?.datumFrom === date &&
                                                ` ${inIntervall?.typSnippet?.kuerzel}`}
                                        </chakra.span>
                                    </MenuButton>
                                    <Box>
                                        <MenuList>
                                            <MenuItem onClick={onEdit}>
                                                {canEdit ? 'Bearbeiten' : 'Ansehen'}
                                            </MenuItem>
                                            {canDelete && (
                                                <>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        color="red.400"
                                                        data-test-id={`delete-${inIntervall.abwesenheitId}`}
                                                        onClick={() =>
                                                            onDelete({
                                                                id: inIntervall.abwesenheitId,
                                                            })
                                                        }
                                                    >
                                                        Löschen
                                                    </MenuItem>
                                                </>
                                            )}
                                        </MenuList>
                                    </Box>
                                </Menu>
                            )}
                        </GridItem>
                    );
                })}
            </SimpleGrid>
        </HStack>
    );
};

function joinAbsences(absences = []) {
    const periodSummary = [];
    const cache = absences?.reduce((acc: any, props: any, idx) => {
        const abwesenheitId = props?.id;
        const indexIsZero = idx === 0;
        const pickedItems = {
            abwesenheitId,
            abwesenheitIds: indexIsZero
                ? [abwesenheitId]
                : [...acc?.[idx - 1].abwesenheitIds, abwesenheitId],
            ...pick(
                props,
                'datumFrom',
                'datumUntil',
                'typSnippetId',
                'typSnippet',
                'mitarbeiterId'
            ),
        };
        const values = [...acc, { id: idx, ...pickedItems }];

        // TODO: obsolet since UCPWO-172 => pls clean up on next iteration (tailwind, shadcn)
        // if (!indexIsZero) {
        //     const indexBefore = acc?.[idx - 1];
        //     // ⚠️ before joining absences there must be checked if the typSnippets differs
        //     const isSameTypeSnippetId = indexBefore?.typSnippetId === pickedItems?.typSnippetId;
        //     const diff = differenceInBusinessDays(
        //         new Date(pickedItems.datumFrom),
        //         new Date(indexBefore?.datumUntil)
        //     );

        //     if (diff === 1) {
        //         return [...acc, { id: isSameTypeSnippetId ? indexBefore.id : idx, ...pickedItems }];
        //     }
        //     return values;
        // }

        return values;
    }, []);

    const periods = cache.reduce(
        (
            acc: any,
            {
                id,
                datumFrom,
                datumUntil,
                typSnippetId,
                typSnippet,
                mitarbeiterId,
                abwesenheitId,
                abwesenheitIds,
            }: any
        ) => ({
            ...acc,
            [id]: {
                datumFrom: acc?.[id]?.datumFrom || datumFrom,
                datumUntil,
                typSnippetId,
                typSnippet,
                mitarbeiterId,
                abwesenheitId,
                abwesenheitIds,
            },
        }),
        {}
    );

    for (const i in periods) {
        periodSummary.push(periods[i]);
    }

    return periodSummary;
}
