import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiMoreHorizontal } from 'react-icons/fi';
import { format, differenceInMinutes, addMinutes } from 'date-fns';
import {
    HStack,
    Box,
    Text,
    VStack,
    IconButton,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    MenuDivider,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    useBoolean,
    useOutsideClick,
    useMediaQuery,
    BoxProps,
    Portal,
} from '@chakra-ui/react';
import { useModal } from '~/hooks/useModal';
import { TYP_MAP } from '~/pages/projects/meta/data/events.schema';
import { Modals } from './EventModal';
import { getClient, join, generateProjectNumberOutOfSubprojectData } from '~/utils';
import { Question } from '~/pages/projects/components/Question';
import { usePdf } from '~/pages/projects/hooks/usePdf';
import { useEventProjectdetails } from '~/pages/projects/ui/Calendar/hooks/useEventProjectDetails';
import { pick } from 'lodash';
import { usePermission } from '~/hooks';

export function ScheduleEvent(props: any) {
    const [{ left, top, original = {} }, setPortal] = React.useState<{
        left: string;
        top: string;
        [key: string]: any;
    }>({ left: '0px', top: '0px' });
    const [showPortal, setShowPortal] = useBoolean(false);
    const client = getClient(original?.subprojekt?.beteiligte);

    const info = {
        title: join(
            [
                original?.title,
                original?.subprojekt
                    ? generateProjectNumberOutOfSubprojectData(original?.subprojekt)
                    : '',
            ],
            ' → '
        ),
        geplant: join(
            [
                original?.geplant ? format(new Date(original?.geplant), 'dd.MM.yyyy HH:mm') : '',
                original?.dauer ? original?.dauer + ' min' : '',
            ],
            ' - '
        ),
        ...(client && {
            client: join([client?.name, client?.vorname, client?.firma1, client?.firma2]),
        }),
        street: original?.subprojekt?.projekt?.strasse,
        city: join([original?.subprojekt?.projekt?.plz, original?.subprojekt?.projekt?.ort], ' '),
        comment: original?.bemerkung,
    };
    return (
        <>
            <Portal>
                <VStack
                    maxW="350px"
                    fontSize={12}
                    fontWeight="medium"
                    spacing={0}
                    align="start"
                    p={3}
                    opacity={showPortal ? 1 : 0}
                    bg="yellow.200"
                    boxShadow="lg"
                    rounded="md"
                    pointerEvents="none"
                    position="fixed"
                    transition="opacity 0.2s ease-in-out"
                    zIndex={100000000}
                    left={left}
                    top={top}
                >
                    {Object.values(info)?.map((text: any, idx: number) => (
                        <Text fontWeight={idx === 0 ? 'bold' : 'revert'} key={`${idx}_${text}`}>
                            {text}
                        </Text>
                    ))}
                </VStack>
            </Portal>
            <ScheduleEventCard onSetPortal={setPortal} onSetShowPortal={setShowPortal} {...props} />
        </>
    );
}

export function ScheduleEventCard({
    data,
    onDelete,
    scheduleType,
    locationState,
    onSetPortal,
    onSetShowPortal,
    currentBranchIsEnabled = false,
}: any) {
    const projecttype = data?.original?.subprojekt?.projekttyp?.name;
    const showWattro = ['Trocknung', 'Vorarbeiten', 'Nacharbeiten'].includes(projecttype);
    const showPreparatoryWorkAndRework = ['Vorarbeiten', 'Nacharbeiten'].includes(projecttype);
    const { canDelete, canEdit } = usePermission('project.schedule');
    const [isHighlighted, setIsHighlighted] = React.useState(
        locationState?.termin?.id === data?.original?.id
    );
    const pdf: any = usePdf({
        projectId: data?.original?.subprojekt?.projekt?.id,
        subprojectId: data?.original?.subprojekt?.id,
    });
    const { onOpen, onClose, isOpen, onToggle } = useDisclosure();
    const [menuIsOpen, setMenuIsOpen] = useBoolean();
    const [listenTo, setListenTo] = useBoolean();
    const ref: any = React.useRef();
    useOutsideClick({
        ref,
        handler: () => {
            setIsHighlighted(false);
            onClose();
        },
    });
    const {
        id,
        bg,
        diff,
        edit,
        goTo,
        title,
        street,
        tooltip,
        hasPrio,
        projectNo,
        subprojektId,
        overdueProps,
        isAbwesenheit,
        zipCodeLocation,
    } = useData(data);
    const { ltOrEq15, eq15, eq30, eq45, ltOrEq30 } = diff;
    const [isLargerThan575] = useMediaQuery('(min-width: 575px)');
    const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');
    const [isMobile] = useMediaQuery('(min-width: 480px)');
    const [isTablet] = useMediaQuery('(min-width: 1350px)');
    const isNachPerson = scheduleType === 'week';
    const [prioOutOfSight, setPrioOutOfSight] = React.useState<boolean>();
    const [projectNoOutOfSight, setProjectNoOutOfSight] = React.useState<boolean>();
    const [addressOutOfSight, setAddressOutOfSight] = React.useState<boolean>();
    const prioRef: any = React.useRef();
    const projectNoRef: any = React.useRef();
    const addressRef: any = React.useRef();
    const titleRef: any = React.useRef();

    const tabletAndMobileCondition =
        (isNachPerson && (ltOrEq15 || !isTablet)) || (!isNachPerson && (ltOrEq15 || !isMobile));

    const onOperationalReports = (props: Record<string, any> = {}) => pdf?.operationalReport(props);
    const onHandFile = () => {
        pdf?.coverSheet();
        pdf?.measurementLog();
    };

    React.useEffect(() => {
        const titleRect = titleRef?.current?.getBoundingClientRect();
        const prioRect = prioRef?.current?.getBoundingClientRect();
        const projectNoRect = projectNoRef?.current?.getBoundingClientRect();
        const addressRect = addressRef?.current?.getBoundingClientRect();

        if (titleRect) {
            hasPrio && setPrioOutOfSight(prioRect.x >= titleRect.right);
            projectNoRect && setProjectNoOutOfSight(projectNoRect.x >= titleRect.right);
            addressRect && setAddressOutOfSight(addressRect.x >= titleRect.right);
        }

        const setAddress = () => {
            const titleRect = titleRef?.current?.getBoundingClientRect();
            const addressRect = addressRef?.current?.getBoundingClientRect();
            titleRect && addressRect && setAddressOutOfSight(addressRect.x >= titleRect.right);
        };

        window.addEventListener('resize', setAddress);
        return () => window.removeEventListener('resize', setAddress);
    }, [titleRef, addressRef, projectNoRef, prioRef, hasPrio, data]);

    React.useEffect(() => {
        let mounted = true;
        const handler = (e: any) => {
            const portal = {
                event: e,
                left: e.clientX + 15 + 'px',
                top: e.clientY + 15 + 'px',
                original: data?.original || {},
            };
            onSetPortal(portal);
        };
        if (ref && ref.current && mounted) {
            ref.current.addEventListener('mousemove', handler);
        }

        return () => {
            if (ref && ref.current && mounted) {
                ref.current.removeEventListener('mousemove', handler);
            }
            mounted = false;
        };
    }, [ref.current]);

    return (
        <>
            <Question
                isCentered={false}
                listenTo={listenTo}
                question="Änderungen verwerfen!"
                description="Möchten Sie mit dem Löschen fortfahren?"
                onConfirmation={() =>
                    onDelete({
                        id,
                        subprojektId,
                        version: data?.original?.version,
                        forceOverwrite: true,
                    })
                }
            />
            <Popover
                placement="bottom"
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <HStack
                        mx={2}
                        pr={1}
                        h="full"
                        ref={ref}
                        bg="white"
                        spacing={0}
                        rounded="md"
                        borderWidth={1}
                        cursor="default"
                        overflow="hidden"
                        align="flex-start"
                        onClick={onToggle}
                        onMouseEnter={onSetShowPortal.on}
                        onMouseLeave={onSetShowPortal.off}
                        borderColor="gray.300"
                        _hover={{ boxShadow: 'outline', borderColor: '#CBD5E0' }}
                        {...(isHighlighted && { boxShadow: 'outline' })}
                        {...overdueProps}
                    >
                        <Box p={eq15 ? 1 : 2} h="full">
                            <Box h="full" bg={bg} w={2} rounded="md" />
                        </Box>
                        <Box py={eq15 ? 1 : 2} h="full" w="full">
                            <Box
                                h="full"
                                w="full"
                                rounded="md"
                                display="flex"
                                borderWidth={0}
                                overflow="hidden"
                                borderColor="gray.300"
                                flexFlow="column wrap"
                            >
                                <Box
                                    p={0}
                                    ref={titleRef}
                                    display="flex"
                                    width="inherit"
                                    flexFlow="row wrap"
                                    alignContent="flex-start"
                                >
                                    <Text
                                        mr={2}
                                        color="gray.800"
                                        mb={ltOrEq30 ? 10 : 1}
                                        fontSize={eq15 ? 10 : 14}
                                        fontWeight={eq15 ? 500 : 600}
                                        {...(!isLargerThan1100 && eq30 && { h: 'full' })}
                                        {...(scheduleType === 'week' && ltOrEq30 && { h: '100%' })}
                                    >
                                        {title}
                                    </Text>
                                    {hasPrio && prioOutOfSight && (
                                        <Badge
                                            data-test-id="prio-badge"
                                            mb={!isLargerThan575 && eq45 ? 10 : 1}
                                            {...(eq15 && { fontSize: 10, h: 10, rounded: 'none' })}
                                        >
                                            PRIO
                                        </Badge>
                                    )}
                                    {projectNo && projectNoOutOfSight && (
                                        <Badge
                                            bg={bg}
                                            {...(eq15 && { fontSize: 10, h: 10, rounded: 'none' })}
                                        >
                                            {projectNo}
                                        </Badge>
                                    )}
                                </Box>
                                <VStack
                                    ref={addressRef}
                                    mb={1}
                                    color="gray.800"
                                    fontWeight={400}
                                    fontSize={12}
                                    width="inherit"
                                    align="flex-start"
                                    spacing={0}
                                >
                                    <Text as="span">{street}</Text>
                                    <Text as="span">{zipCodeLocation}</Text>
                                </VStack>
                                <Badge bg={bg} ref={projectNoRef}>
                                    {projectNo}
                                </Badge>
                                {hasPrio && (
                                    <Badge ref={prioRef} data-test-id="prio-badge">
                                        PRIO
                                    </Badge>
                                )}
                            </Box>
                        </Box>
                        {!isAbwesenheit && (
                            <Box pt={1} display={tabletAndMobileCondition ? 'none' : 'revert'}>
                                <Menu placement="bottom">
                                    <MenuButton
                                        m={1}
                                        top={2}
                                        size="sm"
                                        right={2}
                                        variant="ghost"
                                        as={IconButton}
                                        color="gray.700"
                                        position="sticky"
                                        aria-label="Options"
                                        data-test-id={`menu-${id}`}
                                        onClick={setMenuIsOpen.toggle}
                                        icon={<FiMoreHorizontal />}
                                    />
                                    <Portal>
                                        <MenuList
                                            color="gray.700"
                                            onMouseEnter={onSetShowPortal.off}
                                            fontSize={14}
                                            zIndex={9999}
                                        >
                                            <MenuItem onClick={edit} data-test-id={`edit-${id}`}>
                                                {canEdit ? 'Bearbeiten' : 'Ansehen'}
                                            </MenuItem>
                                            {subprojektId && currentBranchIsEnabled && (
                                                <MenuItem
                                                    onClick={goTo}
                                                    data-test-id={`goto-${id}`}
                                                >
                                                    Projekt Details
                                                </MenuItem>
                                            )}
                                            <MenuItem onClick={onHandFile}>Akte Drucken</MenuItem>
                                            <MenuItem onClick={onOperationalReports}>
                                                Einsatzberichte Drucken
                                            </MenuItem>
                                            {showWattro && (
                                                <MenuItem
                                                    onClick={() =>
                                                        onOperationalReports({ isWattro: true })
                                                    }
                                                >
                                                    Einsatzberichte (wattro) Drucken
                                                </MenuItem>
                                            )}
                                            {showPreparatoryWorkAndRework && (
                                                <MenuItem
                                                    onClick={pdf?.customizedOperationalReport}
                                                >
                                                    Einsatzbericht VA/WHST Drucken
                                                </MenuItem>
                                            )}
                                            {canDelete && (
                                                <>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        color="red.400"
                                                        data-test-id={`delete-${id}`}
                                                        onClick={setListenTo.on}
                                                    >
                                                        Löschen
                                                    </MenuItem>
                                                </>
                                            )}
                                        </MenuList>
                                    </Portal>
                                </Menu>
                            </Box>
                        )}
                    </HStack>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        py={2}
                        maxW="3xs"
                        fontSize={14}
                        boxShadow="base"
                        color="gray.700"
                        _focus={{ boxShadow: 'sm' }}
                        display={tabletAndMobileCondition ? 'revert' : 'none'}
                        zIndex={9999}
                    >
                        <PopoverArrow />
                        <PopoverContentLink onClick={edit}>
                            {canEdit ? 'Bearbeiten' : 'Ansehen'}
                        </PopoverContentLink>
                        {subprojektId && currentBranchIsEnabled && (
                            <PopoverContentLink onClick={goTo}>Projekt Details</PopoverContentLink>
                        )}
                        <PopoverContentLink onClick={onHandFile}>Akte Drucken</PopoverContentLink>
                        <PopoverContentLink onClick={onOperationalReports}>
                            Einsatzberichte Drucken
                        </PopoverContentLink>
                        {showWattro && (
                            <PopoverContentLink
                                onClick={() => onOperationalReports({ isWattro: true })}
                            >
                                Einsatzberichte (wattro) Drucken
                            </PopoverContentLink>
                        )}
                        {showPreparatoryWorkAndRework && (
                            <PopoverContentLink onClick={pdf?.customizedOperationalReport}>
                                Einsatzbericht VA/WHST Drucken
                            </PopoverContentLink>
                        )}
                        {canDelete && (
                            <PopoverContentLink onClick={setListenTo.on} color="red.400">
                                Löschen
                            </PopoverContentLink>
                        )}
                    </PopoverContent>
                </Portal>
            </Popover>
        </>
    );
}

function PopoverContentLink({ children, onClick, ...props }: BoxProps) {
    return (
        <Box
            w="full"
            py="0.4rem"
            onClick={onClick}
            color="gray.700"
            paddingInlineStart="0.8rem"
            _hover={{ bg: 'gray.100' }}
            {...props}
        >
            {children}
        </Box>
    );
}

function useData(data: any) {
    const navigate = useNavigate();
    const { dispatch, onOpen: onOpenModal } = useModal();
    const {
        id,
        prio,
        dauer,
        geplant,
        erledigt,
        bemerkung,
        subprojekt,
        typSnippetId,
        subprojektId,
        mitarbeiterIdSachbearbeiter,
    } = pick(
        data?.original,
        'id',
        'prio',
        'dauer',
        'geplant',
        'erledigt',
        'bemerkung',
        'subprojekt',
        'typSnippetId',
        'subprojektId',
        'mitarbeiterIdSachbearbeiter'
    );
    const { projekt: project } = subprojekt || {};
    const isAbwesenheit = data?.title === 'Abwesenheit';
    const projecttype = subprojekt?.projekttyp?.name;
    const projecttypeMap = { Leckortung: 'LO', Nacharbeiten: 'NA', Trocknung: 'TR' };
    const projecttypeShort = projecttypeMap?.[projecttype as keyof typeof projecttypeMap];
    const projectNo = [projecttypeShort, project?.lfdNr]?.filter(Boolean)?.join(' ');
    const bg = isAbwesenheit
        ? 'blackAlpha.300'
        : projecttype
        ? TYP_MAP?.[projecttype as keyof typeof TYP_MAP]
        : 'none';
    const hasPrio = prio > 0;
    const projectDetails = useEventProjectdetails(subprojekt);
    const street = project?.strasse;
    const zipCodeLocation = join([project?.plz, project?.ort], ' ');
    const title = isAbwesenheit ? data?.title : data?.original?.typSnippet?.name || projecttype;
    const tooltip = join([street, zipCodeLocation]);
    const diffInMinutes =
        data?.endDate?.m?._d && data?.startDate?.m?._d
            ? differenceInMinutes(new Date(data?.endDate?.m?._d), new Date(data?.startDate?.m?._d))
            : dauer;
    const diff = {
        eq30: diffInMinutes === 30,
        gt30: diffInMinutes > 30,
        ltOrEq30: diffInMinutes <= 30,
        eq15: diffInMinutes === 15,
        gt15: diffInMinutes > 15,
        gt45: diffInMinutes > 45,
        eq45: diffInMinutes === 45,
        ltOrEq15: diffInMinutes <= 15,
        ltOrEq45: diffInMinutes <= 45,
        gtOrEq60: diffInMinutes >= 60,
        gtOrEq75: diffInMinutes >= 75,
    };

    const overdueProps = {
        ...(!Boolean(erledigt) &&
            !isAbwesenheit &&
            geplant &&
            addMinutes(new Date(geplant), dauer) < new Date() && { bg: 'red.50' }),
    };
    const goTo = () =>
        subprojektId && navigate(`/projekte/${subprojekt.projekt.id}/${subprojektId}/details`);

    const edit = () => {
        dispatch?.({
            type: 'setModal',
            data: { modal: Modals.CreateEvent },
        });
        dispatch?.({
            type: 'formState',
            data: {
                id,
                version: data?.original?.version,
                duration: dauer,
                comment: bemerkung,
                title: `Termin bearbeiten`,
                prio: data?.original?.prio > 0,
                typeSnippetId: { value: typSnippetId },
                done: Boolean(data?.original?.erledigt),
                time: format(new Date(geplant), 'HH:mm'),
                planed: format(new Date(geplant), 'yyyy-MM-dd'),
                employeeId: { value: mitarbeiterIdSachbearbeiter },
                ...projectDetails,
            },
        });
        onOpenModal?.();
    };

    return {
        id,
        bg,
        diff,
        edit,
        goTo,
        title,
        street,
        tooltip,
        hasPrio,
        projectNo,
        subprojektId,
        overdueProps,
        diffInMinutes,
        isAbwesenheit,
        zipCodeLocation,
    };
}

const Badge = React.forwardRef(
    (
        {
            children,
            bg = 'red.500',
            fontSize,
            ...props
        }: React.PropsWithChildren<{ bg?: string; [key: string]: any }>,
        ref: any
    ) => (
        <Box
            mr={2}
            width="inherit"
            mb={1}
            ref={ref}
            bg={bg}
            h="fit-content"
            w="fit-content"
            px={2}
            rounded="md"
            {...props}
        >
            <Text color="white" fontSize={fontSize || 14} fontWeight={600} whiteSpace="nowrap">
                {children}
            </Text>
        </Box>
    )
);
